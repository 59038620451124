<template>
    <div class="container">
      <h1>Forgot Password</h1>
      
      <!-- Message -->
      <p v-if="message" class="message">{{ message }}</p>
      
      <!-- Error Message -->
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  
      <!-- Email input for password reset -->
      <form @submit.prevent="resetPassword" class="form">
        <input v-model="email" placeholder="Enter your email" class="input-field" />
        <button type="submit" class="btn">Reset Password</button>
      </form>
    </div>
  </template>
  
  <script>
  import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
  const auth = getAuth();
  export default {
    data() {
      return {
        email: '',
        errorMessage: '',
        message: ''
      };
    },
    methods: {
      resetPassword() {
        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            this.message = 'Password reset email sent. Check your inbox!';
            this.errorMessage = '';  // Clear any error messages
          })
          .catch(error => {
            this.errorMessage = error.message;
            this.message = '';  // Clear the success message
          });
      }
    }
  };
  </script>
  
  <style src="../styles/forms.css" scoped></style>
  <style scoped>  
  
  .message {
    color: green;
    margin-top: 10px;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  </style>
  