<template>
    <div>
      <h1>Admin Panel</h1>
      <button @click="$router.push('/app')">Navigate Home</button>
      <table>
        <thead>
          <tr>
            <!-- <th>User UID</th> -->
            <th>Display Name</th>
            <th>Email</th>
            <th>Create Date</th>
            <th>Sign-in Date</th>
            <th>Disabled</th>
            <th>Role</th>
            <th>Quota</th>
            <th>Usage</th>
            <!-- <th>Actions</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.uid">
            <!-- <td>{{ user.uid }}</td> -->
            <td>{{ user.displayName }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.creationTime }}</td>
            <td>{{ user.lastSignInTime }}</td>
            <td>{{ user.disabled }}</td>
            <td>{{ user.role }}</td>            
            <td>
              <input v-model="user.quota" type="number" @change="updateQuota(user)" />
            </td>
            <td>{{ user.usage }}</td>
            <td><button @click="deleteUser(user.uid)">Delete</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { doc, updateDoc, deleteDoc } from "firebase/firestore";

  import { db } from '../firebase';
  import { getAuth} from 'firebase/auth';

  const auth = getAuth();
  const serverURL = "https://us-central1-sprintersvectorizer.cloudfunctions.net";
  // const serverURL = "http://localhost:5000/sprintersvectorizer/us-central1";

  export default {
    data() {
      return {
        users: []
      };
    },
    mounted() {
    this.fetchUsers();
  },
  methods: {
    async getToken() {
      let idToken='';
      await auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idTokenRes) {
        // Send token to your backend via HTTPS
        idToken=idTokenRes;
        // ...
      }).catch(function(error) {
        // Handle error
        console.log('Error', error);
      });
      return idToken;
    },
    async fetchUsers() {
      let idToken=await this.getToken();
      const response = await fetch(serverURL+'/fetchusers', {
        method: 'POST',
        headers:{'authorization':idToken}
      });
      if(response.status==200){
        let jsonRes = await response.json()
        this.users = jsonRes.userDetails
        console.log('jsonRes',jsonRes);
      }
    },
    async updateQuota(user) {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, { quota: user.quota });
    },
    async deleteUser(uid) {
      if (confirm("Are you sure you want to delete this user?")) {
        const userRef = doc(db, "users", uid);
        await deleteDoc(userRef);
        this.fetchUsers();
        // Optionally: Also delete from Firebase Authentication
        // Note: You'd typically use Firebase Functions to do this securely from the server side
      }
    }
  }
  };
  </script>
  
  <style scoped>
  table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      font-size: 16px;
      text-align: left;
  }
  
  th, td {
      padding: 10px 15px;
      border-bottom: 1px solid #ddd;
  }
  
  th {
      background-color: #f2f2f2;
      font-weight: 500;
  }
  
  tr:hover {
      background-color: #f5f5f5;
  }
  
  input[type="number"] {
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 70px;
      text-align: right;
  }
  
  button {
      padding: 5px 10px;
      border: none;
      border-radius: 4px;
      background-color: #f44336;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;
  }
  
  button:hover {
      background-color: #d32f2f;
  }
  </style>
  