<template>
    <div class="container">
    </div>
</template>
<script>
import { getAuth } from 'firebase/auth';

const auth = getAuth();

export default {
    mounted() {
        console.log('mounted');
        auth.signOut();
    },
  methods: {
    
  }
};
</script>
