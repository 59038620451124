import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LandingView from '../views/LandingView.vue';
import EnterView from '../views/EnterView.vue';
import SignUp from '../views/SignUp.vue';
import LogOut from '../views/LogOut.vue';
import AdminView from '../views/AdminView.vue';

import ForgotPassword from '../views/ForgotPassword.vue';

import { getAuth } from 'firebase/auth';

const auth = getAuth();

import { onAuthStateChanged } from 'firebase/auth';



const routes = [
  {
    path: '/',
    name: 'LandingView',
    component: LandingView
  },
  {
    path: '/app',
    name: 'HomeView',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'LogOut',
    component: LogOut,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/enter',
    name: 'EnterView',
    component: EnterView
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  }
  // ... add more routes as needed
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else if (to.path === '/' && isAuthenticated) {
    next('/app');
  } else {
    next();
  }
});

onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      setTimeout(() => {
        router.push('/app')
      }, 1000);
      // Store user in Vuex store or a local state, etc.
    } else {
      // User is signed out
      router.push('/')
      console.log('User is not signed in');
    }
  });

export default router;
