import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA7JWoCfPlRCTOrUODjCGTXlyoRS4G17LY",
    authDomain: "sprintersvectorizer.firebaseapp.com",
    projectId: "sprintersvectorizer",
    storageBucket: "sprintersvectorizer.appspot.com",
    messagingSenderId: "535156400307",
    appId: "1:535156400307:web:c05a1957be71b2211710c6",
    measurementId: "G-N6G5HTV6D6"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);