<template>
    <div class="container">
      <h1>Sign Up</h1>
      
      <!-- Error Message -->
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  
      <!-- Email/Password Sign Up -->
      <form @submit.prevent="signUpWithEmail" class="form">
        <input v-model="displayName" placeholder="Full Name" class="input-field" />
        <input v-model="email" placeholder="Email" class="input-field" />
        <input type="password" v-model="password" placeholder="Password" class="input-field" />
        <button type="submit" class="btn">Sign Up with Email</button>
      </form>
    </div>
  </template>
  
  <script>
  import {getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
  const auth = getAuth();
  
  export default {
    data() {
      return {
        displayName:'',
        email: '',
        password: '',
        errorMessage: ''
      };
    },
    methods: {
        signUpWithEmail() {
        createUserWithEmailAndPassword(auth, this.email, this.password)
            .then(async (userCredential) => {              
            // Update the user's profile with their display name
            return updateProfile(userCredential.user, {
                displayName: this.displayName
            });
            })
            .then(() => {
            // Successfully registered user and updated profile
            console.log("User registered with display name:", this.displayName);
            this.$router.push('/sign-in'); 
            })
            .catch(error => {
            // Handle registration errors
            this.errorMessage = error.message;
            });
        }
    }
  };
  </script>
  
  <style src="../styles/forms.css" scoped></style>
  