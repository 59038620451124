<template>
  <div>
    <img style="float: left" src="../images/logo.png" alt="Logo Image" width="200" />
    <span style="float: right">
      Hello {{ userName }}
      <button v-if="role == 'admin'" @click="$router.push('/admin')">
        Navigate Admin
      </button>
      <button @click="$router.push('/logout')">Logout</button>
    </span>
    <br />
    <br />
    <br />
    <br />
    <h1>Vectorize your artwork in seconds!</h1>
    <hr />

    <!-- File Upload -->
    <input type="file" multiple accept="image/*" @change="previewFiles" />

    <!-- Image Previews with Remove Option -->
    <div v-for="(file, index) in files" :key="index">
      <img :src="file.preview" alt="Image Preview" width="100" />
      <button :disabled="isConverting" @click="removeImage(index)">
        Remove
      </button>
    </div>

    <!-- Convert Button -->
    <button v-if="files.length && errorMessage==''" @click="convertImages" :disabled="isConverting">
      Convert
    </button>
    Avaliable Quota: {{ quota }}
    <span style="float: right;" v-if="errorMessage" class="error">{{ errorMessage }}</span>
    <button style="float: right;" v-if="quota==0 && usage==1 && errorMessage && paymentbutton_url" @click="openNewTab(paymentbutton_url)">Start Your Trial</button>
    <span style="float: right;" v-if="quota>1 || usage>1">
      Need additional credits? Just $0.25 each.
      <button @click="openNewTab(paymentbutton_url2)">10</button>
      <button @click="openNewTab(paymentbutton_url3)">30</button>
      <button @click="openNewTab(paymentbutton_url4)">100</button>
      <button @click="openNewTab(paymentbutton_url5)">200</button>    
    </span>
    <div v-if="isConverting" class="progress">
      Converting... {{ progress }}/{{ files.length }}
    </div>

    <!-- Converted Images with Download Option -->
    <!-- <div v-for="(file, index) in convertedFiles" :key="file.name+index">
        <img :src="file.svgUrl" alt="Converted Image" width="100">
        <button @click="downloadIndividual(file.svgUrl)">Download</button>
      </div> -->
    <hr />
    <div id="assetBrowser" v-if="classified_userassets.length">
      <hr>
      <div v-for="[type,assets] in classified_userassets" :key="type">
        <h4>{{ type }}</h4>
        <div class="inline-assets">          
        <a v-for="asset in assets" :key="asset.name"
          :href="serverURL + '/getasset/' + asset.name"
          target="_blank"              
          >          
          <img
            :src="serverURL + '/getasset/' + asset.name"
            :title="'Click to download (Size: '+Math.round(asset.size/1024)+' KB)'"
            :alt="asset.fileName"
            style="width:100px;"
            />
        </a>
      </div>
      </div>      
    </div>
  </div>
</template>
<script>
import { getAuth } from "firebase/auth";

const auth = getAuth();
const serverURL = "https://us-central1-sprintersvectorizer.cloudfunctions.net";
const paymentURL_Trial = "https://buy.stripe.com/6oEcNV2ti5mEbWU28c"
const paymentURL_10_25 = "https://buy.stripe.com/cN2g07fg43ewbWUfZ6"
const paymentURL_30_75 = "https://buy.stripe.com/5kA7tB0ladTad0Y5kp"
const paymentURL_100_250 = "https://buy.stripe.com/14kg070la4iAf96cMT"
const paymentURL_200_500 = "https://buy.stripe.com/cN2g07fg43ewbWUfZ6"

// const serverURL = "http://localhost:5000/sprintersvectorizer/us-central1";

export default {
  data() {
    return {
      files: [], // to hold the images for preview
      convertedFiles: [], // to hold the converted images
      uploadedFiles: [],
      isConverting: false, // to check if the conversion process is ongoing
      progress: 0, // to track the number of images converted
      userName: "",
      userEMail: "",
      uid:'',
      errorMessage: "",
      paymentbutton_url:'',
      paymentbutton_url2:'',
      paymentbutton_url3:'',
      paymentbutton_url4:'',
      paymentbutton_url5:'',      
      quota: 0,
      usage: 0,
      role: "",
      userassets: [],
      classified_userassets: [],
      serverURL:serverURL,
    };
  },
  mounted() {
    if (auth.currentUser && auth.currentUser.displayName) {
      this.userName = auth.currentUser.displayName;
      this.userEMail = auth.currentUser.email;
      this.uid = auth.currentUser.uid      
    } else {
      setTimeout(() => {
        if (auth.currentUser && auth.currentUser.displayName) {
          this.userName = auth.currentUser.displayName;
          this.userEMail = auth.currentUser.email;          
          this.uid = auth.currentUser.uid
        }
      }, 500);
    }
    this.getUserRole();
    this.checkQouta();
    this.getUserAssets();
  },
  methods: {
    openNewTab(url) {
        // Replace 'https://example.com' with your desired URL
        console.log('url',url);
        window.open(url, '_blank');
    },
    previewFiles(event) {
      const fileInput = event.target;
      for (let i = 0; i < fileInput.files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.files.push({
            preview: e.target.result,
            raw: fileInput.files[i],
          });
        };
        reader.readAsDataURL(fileInput.files[i]);
      }
    },

    removeImage(index) {
      this.files.splice(index, 1);
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getToken() {
      let idToken = "";
      await auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(function (idTokenRes) {
          // Send token to your backend via HTTPS
          idToken = idTokenRes;
          // ...
        })
        .catch(function (error) {
          // Handle error
          console.log("Error", error);
        });
      return idToken;
    },
    async checkQouta() {
      let idToken = await this.getToken();
      const response = await fetch(serverURL + "/getuserquota", {
        method: "POST",
        headers: { authorization: idToken },
      });
      if (response.status == 200) {
        let jsonRes = await response.json();
        this.quota = jsonRes.quota;
        this.usage = jsonRes.usage;
        if (!this.quota) {
          this.errorMessage =
            "Your Quota is 0";
            // this.paymentbutton_url = paymentURL_Trial+'?prefilled_email='+this.userEMail+'&client_reference_id='+this.uid
            this.paymentbutton_url = paymentURL_Trial+'?prefilled_email='+this.userEMail+'&client_reference_id='+this.uid;            
        }
        this.paymentbutton_url2 = paymentURL_10_25+'?prefilled_email='+this.userEMail+'&client_reference_id='+this.uid;
        this.paymentbutton_url3 = paymentURL_30_75+'?prefilled_email='+this.userEMail+'&client_reference_id='+this.uid;
        this.paymentbutton_url4 = paymentURL_100_250+'?prefilled_email='+this.userEMail+'&client_reference_id='+this.uid;
        this.paymentbutton_url5 = paymentURL_200_500+'?prefilled_email='+this.userEMail+'&client_reference_id='+this.uid;
      }
    },
    async getUserRole() {
      let idToken = await this.getToken();
      const response = await fetch(serverURL + "/getuserrole", {
        method: "POST",
        headers: { authorization: idToken },
      });
      if (response.status == 200) {
        let jsonRes = await response.json();
        this.role = jsonRes.role;
      }
    },
    classifyAssets(assets) {
      const classified = {};
      assets.forEach((item) => {
        let newType = item.contentType.toString().includes("svg")
          ? "Vectorized"
          : "Raw";
        if (!classified[newType]) {
          classified[newType] = [];
        }
        classified[newType].push(item);
      });
      this.classified_userassets = Object.entries(classified) ;
    },
    async getUserAssets() {
      let idToken = await this.getToken();
      const response = await fetch(serverURL + "/getuserassets", {
        method: "POST",
        headers: { authorization: idToken },
      });
      if (response.status == 200) {
        let jsonRes = await response.json();
        console.log("jsonRes", jsonRes);
        this.userassets = jsonRes.items;
        this.classifyAssets(jsonRes.items);
      }
    },
    async convertImages() {
      this.isConverting = true;
      this.progress = 1;
      this.uploadedFiles = [];
      this.convertedFiles = [];
      for (let i = 0; i < this.files.length; i++) {
        const formData = new FormData();
        formData.append("image", this.files[i].raw);

        // const functions = getFunctions();
        // let uploadFunction = httpsCallable(functions, 'upload',);

        try {
          // const response = await uploadFunction(formData);
          let idToken = await this.getToken();
          const response = await fetch(serverURL + "/upload", {
            method: "POST",
            body: formData,
            headers: { authorization: idToken },
          });
          if (response.status == 200) {
            const data = await response.json();
            await this.delay(2000);

            const response2 = await fetch(
              serverURL + "/vectorize/" + data.filepath,
              {
                headers: { authorization: idToken },
              }
            );
            console.log(response2.status);
            if (response2.status == 200) {
              const data2 = await response2.json();
              data2.svgUrl = serverURL + "/" + data2.svgUrl;

              this.convertedFiles.push(data2);
            } else {
              if (response2.status == 500) {
                let error = await response2.text();
                this.errorMessage = error;
                console.log(error);
              }
            }
          } else {
            if (response.status == 500) {
              let error = await response.text();
              this.errorMessage = error;
              console.log(error);
            }
          }
          this.progress += 1;
          await this.checkQouta();
        } catch (error) {
          console.error("Error converting image:", error);
        }
      }
      this.getUserAssets();
      this.files = [];
      this.convertedFiles = [];
      this.uploadedFiles = [];
      this.isConverting = false;
    },
    downloadIndividual(fileName) {
      // Assuming server provides the file with its name
      const url = fileName;
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped>
div {
  font-family: "Arial", sans-serif;
  max-width: 80vw;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
}

input[type="file"] {
  margin-bottom: 20px;
}
.imgclass {
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.2s;
}

.imgclass:hover {
  transform: scale(1.1);
}

img {
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.2s;
}

img:hover {
  transform: scale(1.2);
}

button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:disabled {
  background-color: #c1c1c1;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

input[type="file"] {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

input[type="file"]:disabled {
  background-color: #c1c1c1;
  cursor: not-allowed;
}

input[type="file"]:hover:not(:disabled) {
  background-color: #0056b3;
}

.progress {
  margin-top: 20px;
  font-weight: bold;
  color: #007bff;
}
.error {
  color: red;
  margin-top: 10px;
}
.asset-section {
  margin-bottom: 20px;
}

.inline-assets {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line if necessary */
  gap: 10px; /* Space between items */
}

.inline-assets img {
  max-width: 100px; /* You can adjust this as per your preference */
  height: auto;
}
</style>


