<template>
    <div class="main-container">
        <!-- Sign In -->
        <div class="container sign-in">
            <h1>Sign In</h1>

            <button @click="signInWithGoogle" class="btn">Sign In with Google</button>  

            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>

            <form @submit.prevent="signInWithEmail" class="form">
                <input v-model="email" placeholder="Email" class="input-field" />
                <input type="password" v-model="password" placeholder="Password" class="input-field" />
                <button type="submit" class="btn">Sign In with Email</button>
            </form>
            <a @click="$router.push('forgot-password')">Forgot password? Reset it here</a>
        </div>

        <!-- Sign Up -->
        <div class="container sign-up">
            <h1>Sign Up</h1>
            <p v-if="errorMessage2" class="error">{{ errorMessage2 }}</p>

            <form @submit.prevent="signUpWithEmail" class="form">
                <input v-model="displayName" placeholder="Full Name" class="input-field" />
                <input v-model="email2" placeholder="Email" class="input-field" />
                <input type="password" v-model="password2" placeholder="Password" class="input-field" />
                <button type="submit" class="btn">Sign Up with Email</button>
            </form>
        </div>
    </div>
</template>


<script>
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
const auth = getAuth();

export default {
  data() {
    return {
      displayName: "",
      email: "",
      password: "",
      email2: "",
      password2: "",
      errorMessage: "",
      errorMessage2: "",
    };
  },
  methods: {
    signInWithGoogle() {
      this.errorMessage = "";
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const uid = result.user.uid;
          console.log("tokuiden", uid);
          const user = result.user;
          auth.currentUser = user;
          console.log("User signed in:", user);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("Error signing in:", errorCode, errorMessage);
        });
    },
    signInWithEmail() {
      this.errorMessage = "";
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("User signed in:", user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          this.errorMessage = error.message;
          console.error("Error signing in:", errorCode, errorMessage);
        });
    },
    signUpWithEmail() {
      createUserWithEmailAndPassword(auth, this.email2, this.password2)
        .then(async (userCredential) => {
          return updateProfile(userCredential.user, {
            displayName: this.displayName,
          });
        })
        .then(() => {
          console.log("User registered with display name:", this.displayName);
        })
        .catch((error) => {
          this.errorMessage2 = error.message;
        });
    },
  },
};
</script>

<style src="../styles/forms.css" scoped />
<style scoped>
.main-container {
    display: inline-flex;
    justify-content: space-between;
    align-items: start; /* To ensure they align at the top if one form is longer than the other */
    gap: 2em; /* Adds some space between the two containers */    
}

.container.sign-in, .container.sign-up {
    flex: 1; /* Takes up an equal amount of space */
    box-sizing: border-box;
    padding: 1em;
    margin: 1em;
    width: 100%;
}

.divider {
    width: 1px; /* Just a thin line, can be adjusted */
    background-color: #e0e0e0; /* Light gray, can be adjusted */
    height: 100%;
}

</style>
