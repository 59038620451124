<template>
  <div>
    <header>
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand main-heading" href="#"><img src="../images/logo.png" /></a>
          <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            class="navbar-toggler" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="#Pricing">Pricing</a>
              </li>
              <li class="nav-item dropdown">
                <a  class="nav-link bg-green" data-bs-toggle="dropdown">Create Account</a>
                <div id="createaccount-dropdown-menu" class="dropdown-menu">
                  <div class="container sign-up" style="min-width:200px">
                    <p class="error" v-if="errorMessage2">{{ errorMessage2 }}</p>
                    <a @click="signInWithGoogle" class="btn">Use Google</a>
                    <form @submit.prevent="signUpWithEmail" class="form">
                      <input class="input-field" placeholder="Full Name" v-model="displayName" id="displayNaneField" />
                      <br>
                      <input class="input-field" placeholder="Email" v-model="email2" />
                      <br>
                      <input class="input-field" placeholder="Password" type="password" v-model="password2" />
                      <br>
                      <button class="btn" type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link bg-blue" data-bs-toggle="dropdown">Log In</a>
                <div class="dropdown-menu">
                  <div class="container sign-in" style="min-width:250px">
                    <a @click="signInWithGoogle" class="btn">Use Google</a>
                    <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
                    <form @submit.prevent="signInWithEmail" class="form">
                      <input class="input-field" placeholder="Email" v-model="email" />
                      <br>
                      <input class="input-field" placeholder="Password" type="password" v-model="password" />
                      <br>
                      <button class="btn" type="submit">Submit</button>
                    </form>
                    <hr>
                    <a style="padding:0px; margin: 0px;" @click="$router.push('forgot-password')">Froget password?</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <section class="banner">
      <div class="container">
        <div class="row align-items-center text-center justify-content-center">
          <div class="col-sm-12 col-md-12">
            <h4>Instantly Convert</h4>
            <h1 class="main-heading">
              JPG, PNG, GIF Files to PDF, SVG, EPS Vectors
            </h1>
            <p>
              Trace Pixels To Vectors in Full Color. Convert your JPEG and PNG
              bitmaps to SVG vectors quickly and easily. Fully Automated. Using
              AI.
            </p>
            <a @click="openCreateAccount" class="drag-btn"><i class="fa-light fa-cloud-arrow-up"></i> Upload or Drag
              your
              artwork</a>
          </div>
        </div>
      </div>
    </section>
    <section class="afer-before">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-12">
            <h2 class="main-heading text-center">SEE IT IN ACTION!</h2>
            <div class="cstm-before-after">
              <h6>Before</h6>
              <div id="page">
                <div class="wrapper">
                  <div class="before">
                    <img class="content-image" draggable="false" src="../images/after.png" />
                  </div>
                  <div class="after">
                    <img class="content-image" draggable="false" src="../images/before.png" />
                  </div>
                  <div class="scroller">
                    <svg class="scroller__thumb" height="100" viewbox="0 0 100 100" width="100"
                      xmlns="http://www.w3.org/2000/svg">
                      <polygon points="0 50 37 68 37 32 0 50" style="fill: #fff"></polygon>
                      <polygon points="100 50 64 32 64 68 100 50" style="fill: #fff"></polygon>
                    </svg>
                  </div>
                </div>
              </div>
              <h6>After</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="great-for text-center">
      <div class="container">
        <h2 class="main-heading">A NEW SOLUTIONS TO AN OLD PROBLEM</h2>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-4">
            <div class="icon-box">
              <i class="fa-regular fa-print"></i>
              <h4>Save Time</h4>
              <p>
                Graphic designers, illustrators, and logo designers can
                significantly save time by avoiding the manual process of
                vectorizing images, Freeing up their schedule to do what they do
                best.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="icon-box">
              <i class="fa-light fa-apple-whole"></i>
              <h4>Instant Vectorization</h4>
              <p>
                Our innovative solution empowers Architects and Digital Artists
                to effortlessly convert BMP images to vectors. This
                game-changing tool alleviates the hassle of time-consuming
                quotes and minimizes lead times, ensuring a seamless experience
                for professionals in these fields.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="icon-box">
              <i class="fa-light fa-computer"></i>
              <h4>High-Accuracy Vectorization</h4>
              <p>
                We offer high-precision BMP to vector conversion services
                exclusively designed for Print Production Artists and Engravers.
                It promises the highest level of preciseness required to satisfy
                the demand of their specific work.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="icon-box">
              <i class="fa-regular fa-heart"></i>
              <h4>Centralized Artwork Management</h4>
              <p>
                Our platform provides a unified solution for artwork management,
                assisting all users, particularly Signage Designers. It allows
                users to organize, modify, and retrieve vectorized photos in an
                effective manner, improving their workflow and increasing
                overall productivity.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="icon-box">
              <i class="fa-regular fa-heart"></i>
              <h4>Enhanced Efficiency</h4>
              <p>
                Minimizing back-and-forth "ping-pong" communication allows
                designers and clients to collaborate more efficiently, resulting
                in quicker decision-making, faster project progress, and
                ultimately, timely project delivery and satisfied customers.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="icon-box">
              <i class="fa-regular fa-heart"></i>
              <h4>Cross-device Compatibility</h4>
              <p>
                Our online solution offers universal access, allowing users to
                conveniently connect from any computer or device. Whether at the
                office or on the go, our platform ensures accessibility and
                convenience for all users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="convert-sec text-center">
      <div class="container">
        <h2 class="main-heading">HOW IT WORKS?</h2>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-4">
            <div class="img-box">
              <img src="../images/image3.jpg" />
              <h4>Step 1. Upload</h4>
              <p>
                Upload a bitmap image and we automatically figure out what
                settings to use and trace the image for you.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="img-box">
              <img src="../images/image2.jpg" />
              <h4>Step 2. Review</h4>
              <p>
                You can preview the vector result, tweak the settings, and even
                request a live human graphic designer to tweak and amend the
                design to you at only $3/hour
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="img-box">
              <img src="../images/image1.jpg" />
              <h4>Step 3. Download &amp; Manage</h4>
              <p>
                Inspect a full preview of the result and download it. Right now
                we support SVG, PDF, EPS, DXF, and PNG, and more formats are
                coming soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="auto-tracer-sec">
      <div class="container">
        <h2 class="main-heading text-center">Technical Specifications</h2>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-regular fa-cloud-bolt"></i>
              <p>
                <strong>Deep Vector Engine:</strong> With extensive expertise in
                this field, we've developed a robust foundation for Vectorizing.
                Our innovative technology combines deep learning networks and
                classical algorithms to provide advanced functionality.
                Leveraging our AI net, we have conducted thorough training to
                ensure optimal performance and accuracy.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-light fa-bezier-curve"></i>
              <p>
                <strong>Vector Graph:</strong> Our exclusive computational
                geometry framework empowers us to perform automatic edits and
                localized optimizations that go beyond the capabilities of
                traditional vector image representations.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-light fa-shapes"></i>
              <p>
                <strong>Full Shape Fitting:</strong> Our approach goes beyond
                mere Bezier curves. We strive for a flawless fit and unmatched
                uniformity by employing intricate geometric shapes whenever
                feasible. Additionally, our comprehensive support extends to
                fully customizable circles, ellipses, rounded rectangles, and
                stars, all of which can feature rounded corners and be rotated
                at any angle.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-light fa-chart-pie-simple-circle-currency"></i>
              <p>
                <strong>Curve Support:</strong> Vector shapes can be created
                using various components, including straight lines, circular
                arcs, elliptical arcs, and quadratic and cubic Bezier curves.
                While many vector graphics software applications simplify
                generalized curves using only cubic Bezier curves, which is
                convenient but limits accuracy, Verctorbot.app stands out by
                supporting the full spectrum of curve types. It ensures precise
                representation and utilization of the appropriate curve type
                where necessary.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-light fa-soap"></i>
              <p>
                <strong>Clean Corners:</strong> Shape outlines typically include
                straight or smoothly transitioning sections that are segmented
                by distinct corners. Our analysis, modeling, and optimization of
                each corner in the Vector Graph allow us to create remarkably
                natural results compared to other vectorizers.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-light fa-bolt"></i>
              <p>
                <strong>High Performance:</strong> We value your time and
                understand the importance of efficiency. That's why we leverage
                state-of-the-art GPUs for deep learning and meticulously execute
                highly parallel classical algorithms on multi-core CPUs. It
                allows us to promptly deliver the industry's finest vectors,
                ensuring an optimal experience for you.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-solid fa-border-center-h"></i>
              <p>
                <strong>Sub-Pixel Precision:</strong> In our process, we
                carefully extract and identify features that are less than a
                single pixel in width. Then, we establish boundaries based on
                the anti-aliasing pixel values. It's vital to pay attention to
                these details.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-regular fa-cloud-bolt"></i>
              <p>
                <strong>Fully Automatic:</strong> The result can be produced
                without any user input, implying that the process does not rely
                on any direct input from the user.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-regular fa-images"></i>
              <p>
                <strong>Image Types:</strong> Initially intended for logos and
                rasterized vector art, the algorithm exhibits exceptional
                performance when applied to scans, photos of sketches, and other
                types of drawn artwork, as well as photographs.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-regular fa-crop-simple"></i>
              <p>
                <strong>Pre-Crop:</strong> To optimize the use of our maximum
                allowable resolution, we provide the option to crop the specific
                portion of your bitmap that you intend to convert into a vector
                image. By only considering the cropped area when it comes to
                resolution limits, you can achieve the highest possible quality
                for the final output.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-regular fa-palette"></i>
              <p>
                <strong>Full Color &amp; Transparancy:</strong> Our support
                includes a full 32-bit color with an alpha channel, a concept we
                prioritized from the very beginning. It allows for partially
                transparent areas and anti-aliasing, all of which are fully
                compatible and supported.
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="cont-box-right">
              <i class="fa-regular fa-ballot-check"></i>
              <p>
                <strong>Export Choices:</strong> Our platform provides support
                for various output formats such as SVG, PDF, EPS, DXF, and PNG.
                Among these, SVG stands out as the most versatile and widely
                supported format and is set as the default option. With our
                comprehensive export settings, you can precisely control the
                drawing of shapes, their grouping, and a range of other
                format-specific options to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="faq-sec" id="Pricing">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-5">
            <h2 class="main-heading text-center">Pricing</h2>
            <div class="price-box">
              <h3>Vectorbot Pro Plan</h3>
              <p>
                1st month FREE, $9.99 / month afterwards for 30-credits<br />Only
                a quarter for each artwork vectorized thereafter<br />Unused
                credits are rolled-over<br />Retain unlimited artworks<br /><span>Billed monthly, cancel anytime</span>
                <br /><br /><span><b>Amend, tweak and add to your existing artwork by a human
                    graphic designer at just $3/hour (add-on)</b></span>
              </p>
              <p></p>
              <p></p>
              <p></p>
              <a @click="openCreateAccount" class="btn" target="_blank">Start Vectorizing</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="programs-sec faq-sec">
      <div class="container">
        <h2 class="main-heading text-center">Frequently Asked Questions</h2>
        <div class="accordion mb-3" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button aria-controls="collapseOne" aria-expanded="true" class="accordion-button"
                data-bs-target="#collapseOne" data-bs-toggle="collapse" type="button">
                What are Bitmap Images?
              </button>
            </h2>
            <div aria-labelledby="headingOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample"
              id="collapseOne">
              <div class="accordion-body">
                Bitmap images, also referred to as raster images, are graphics
                that are represented by pixels. These pixels, organized in a
                grid pattern, combine to form the complete image. This type of
                image encoding allows for greater detail and color variation,
                resulting in visually rich and quality graphics.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button aria-controls="collapseTwo" aria-expanded="false" class="accordion-button collapsed"
                data-bs-target="#collapseTwo" data-bs-toggle="collapse" type="button">
                What are Vector Images?
              </button>
            </h2>
            <div aria-labelledby="headingTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
              id="collapseTwo">
              <div class="accordion-body">
                Vector images are a type of image defined by mathematical
                shapes, like circles and squares, rather than individual pixels.
                It is in contrast to bitmap images, which are made up of pixels
                arranged in a grid. The advantage of vector images is that they
                can be scaled without losing any clarity or becoming pixelated.
                You can think of pixelation as that blocky appearance that often
                occurs when raster images are enlarged.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button aria-controls="collapseThree" aria-expanded="false" class="accordion-button collapsed"
                data-bs-target="#collapseThree" data-bs-toggle="collapse" type="button">
                What is Tracing? Vectorization?
              </button>
            </h2>
            <div aria-labelledby="headingThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
              id="collapseThree">
              <div class="accordion-body">
                Tracing, AKA vectorization, is a technique used to convert
                bitmap images into vector images. This process can be executed
                either manually, known as "hand-tracing," or through computer
                programs, referred to as "auto-tracing." Tracing plays a pivotal
                role in graphic design, enabling the creation of scalable and
                easily editable images.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button aria-controls="collapseFour" aria-expanded="false" class="accordion-button collapsed"
                data-bs-target="#collapseFour" data-bs-toggle="collapse" type="button">
                What Programs Can I Use The Output With?
              </button>
            </h2>
            <div aria-labelledby="headingFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
              id="collapseFour">
              <div class="accordion-body">
                <table>
                  <tr>
                    <th>Software</th>
                    <th>Version</th>
                    <th>EPS</th>
                    <th>SVG</th>
                    <th>PDF</th>
                  </tr>
                  <tr>
                    <td>Adobe Illustrator</td>
                    <td>CC</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Adobe Illustrator</td>
                    <td>CS1-6</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Adobe Illustrator</td>
                    <td>10</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>?</td>
                  </tr>
                  <tr>
                    <td>Adobe Illustrator</td>
                    <td>9</td>
                    <td>Yes</td>
                    <td>?</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>CorelDRAW</td>
                    <td>X3-8</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>CorelDRAW</td>
                    <td>10-12</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>CorelDRAW</td>
                    <td>9</td>
                    <td>Yes</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Xara Xtreme PRO</td>
                    <td>4.0</td>
                    <td>No</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Xara Xtreme PRO</td>
                    <td>3.2</td>
                    <td>No</td>
                    <td>N/A</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Inkscape</td>
                    <td>0.46</td>
                    <td>N/A</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Inkscape</td>
                    <td>0.45</td>
                    <td>N/A</td>
                    <td>Yes</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>FreeHand</td>
                    <td>MX</td>
                    <td>No</td>
                    <td>N/A</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>FireWorks</td>
                    <td>MX 2004</td>
                    <td>Yes</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>OpenOffice Draw</td>
                    <td></td>
                    <td>?</td>
                    <td>?</td>
                    <td>?</td>
                  </tr>
                  <tr>
                    <td>Microsoft Visio</td>
                    <td>2007</td>
                    <td>N/A</td>
                    <td>Yes</td>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <td>OmniGraffle</td>
                    <td>5.2.3</td>
                    <td>Yes</td>
                    <td>N/A</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>OmniGraffle</td>
                    <td>5</td>
                    <td>No</td>
                    <td>N/A</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>OmniGraffle</td>
                    <td>4</td>
                    <td>No</td>
                    <td>N/A</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Intaglio</td>
                    <td>3.1.2</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Intaglio</td>
                    <td>2.9.7</td>
                    <td>No</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>VectorDesigner</td>
                    <td>1.2.0</td>
                    <td>Yes</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>EazyDraw</td>
                    <td>2.6</td>
                    <td>No</td>
                    <td>no</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Lineform</td>
                    <td>1.5</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>WinPCSIGN</td>
                    <td>2012</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Sketch by Bohemian Coding</td>
                    <td>2.4</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <p>© 2023 Vectorbot.app All Rights Reserved</p>
    </footer>
  </div>
</template>
<script>
// import { getAuth } from "firebase/auth";
import "../js/bootstrap.bundle.min.js";
// import "../js/custom.js";
// const auth = getAuth();
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
const auth = getAuth();

export default {
  data() {
    return {
      active: false,
      displayName: "",
      email: "",
      password: "",
      email2: "",
      password2: "",
      errorMessage: "",
      errorMessage2: "",
    };
  },
  mounted() {
    this.addEventListeners();
    this.scrollIt(300); // initial scroll position
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  methods: {
    openCreateAccount(){
      window.scrollTo({top: 0,behavior: 'smooth'})
      let el = document.getElementById('createaccount-dropdown-menu')
      el.setAttribute("class","dropdown-menu show")
      let el2 = document.getElementById('navbarSupportedContent')
      el2.setAttribute("class","navbar-collapse justify-content-end collapse show")
      console.log()
      let el3 = document.getElementById('displayNaneField')
      el3.focus()
      
    },
    signInWithGoogle() {
      this.errorMessage = "";
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const uid = result.user.uid;
          console.log("tokuiden", uid);
          const user = result.user;
          auth.currentUser = user;
          console.log("User signed in:", user);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("Error signing in:", errorCode, errorMessage);
        });
    },
    signInWithEmail() {
      this.errorMessage = "";
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("User signed in:", user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          this.errorMessage = error.message;
          console.error("Error signing in:", errorCode, errorMessage);
        });
    },
    signUpWithEmail() {
      console.log('signUpWithEmail');
      createUserWithEmailAndPassword(auth, this.email2, this.password2)
        .then(async (userCredential) => {
          return updateProfile(userCredential.user, {
            displayName: this.displayName,
          });
        })
        .then(() => {
          console.log("User registered with display name:", this.displayName);
        })
        .catch((error) => {
          this.errorMessage2 = error.message;
        });
    },
    addEventListeners() {
      document
        .querySelector(".scroller")
        .addEventListener("mousedown", this.startScroll);
      document.body.addEventListener("mouseup", this.stopScroll);
      document.body.addEventListener("mouseleave", this.stopScroll);
      document.body.addEventListener("mousemove", this.handleMouseMove);

      // For touch events
      document
        .querySelector(".scroller")
        .addEventListener("touchstart", this.startScroll);
      document.body.addEventListener("touchend", this.stopScroll);
      document.body.addEventListener("touchcancel", this.stopScroll);
    },
    removeEventListeners() {
      document
        .querySelector(".scroller")
        .removeEventListener("mousedown", this.startScroll);
      document.body.removeEventListener("mouseup", this.stopScroll);
      document.body.removeEventListener("mouseleave", this.stopScroll);
      document.body.removeEventListener("mousemove", this.handleMouseMove);

      // For touch events
      document
        .querySelector(".scroller")
        .removeEventListener("touchstart", this.startScroll);
      document.body.removeEventListener("touchend", this.stopScroll);
      document.body.removeEventListener("touchcancel", this.stopScroll);
    },
    startScroll() {
      this.active = true;
      document.querySelector(".scroller").classList.add("scrolling");
    },
    stopScroll() {
      this.active = false;
      document.querySelector(".scroller").classList.remove("scrolling");
    },
    handleMouseMove(e) {
      if (!this.active) return;
      let x =
        e.pageX -
        document.querySelector(".wrapper").getBoundingClientRect().left;
      this.scrollIt(x);
    },
    scrollIt(x) {
      let transform = Math.max(
        0,
        Math.min(x, document.querySelector(".wrapper").offsetWidth)
      );
      document.querySelector(".after").style.width = `${transform}px`;
      document.querySelector(".scroller").style.left = `${transform - 25}px`;
    },
  },
};
</script>
<style src="../styles/style.css"></style>
<style src="../styles/bootstrap.min.css"></style>